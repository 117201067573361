import styled from "styled-components";
import StarImg from "../../assets/star.svg";
import EarthImg from "../../assets/earth.svg";
import CalculatorImg from "../../assets/calculator.svg";
import BlingImg from "../../assets/bling.svg";
import ClockImg from "../../assets/Clock.svg";
import SunglassImg from "../../assets/sunglass.svg";
import LaptopImg from "../../assets/laptop.svg";
import PageImg from "../../assets/Page.svg";
import GraphImg from "../../assets/graph.svg";

const NinethMainWrapper = styled.section`
  height: 732px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f6fe;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: calc(26.3px / 2);
  }
`;

const Textbox = styled.p`
  font-size: 39.9px;
  font-weight: 700;
  color: #809cff;
  margin-right: 12.5px;
`;

interface TextAndImageProps extends React.PropsWithChildren {
  text: string;
}

export const TextAndImage = ({ text, children }: TextAndImageProps) => {
  const TextAndImage = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    font-size: 44.25px;
    color: #5972e4;
    font-weight: 800;
    background-color: #ffffff;
    padding: 8.3px 24.7px;
    border-radius: 76.1px;
    gap: 8px;
    line-height: 81px;
  `;

  return (
    <TextAndImage>
      <p>{text}</p>
      {children && children}
    </TextAndImage>
  );
};

export const NinethMain = () => {
  return (
    <NinethMainWrapper>
      <Container>
        <div>
          <Textbox>어디서든 사용가능한</Textbox>
          <TextAndImage text="웹기반 생산관리 서비스">
            <img src={EarthImg} alt="" />
            <img src={StarImg} alt="" />
          </TextAndImage>
        </div>
        <div>
          <Textbox>복잡한 업무를 깔끔하게</Textbox>
          <TextAndImage text="사용자 친화적인 UI">
            <img src={CalculatorImg} alt="Calculator" />
            <img src={BlingImg} alt="Bling" />
          </TextAndImage>
        </div>
        <div>
          <Textbox>체계적 관리가 가능한</Textbox>
          <TextAndImage text="실시간 모니터링">
            <img src={SunglassImg} alt="sunglass" />
            <img src={LaptopImg} alt="laptop" />
            <img src={ClockImg} alt="clock" />
          </TextAndImage>
        </div>
        <div>
          <Textbox>한 눈에 파악하는</Textbox>
          <TextAndImage text="기업의 현황 리포트">
            <img src={PageImg} alt="note" />
            <img src={GraphImg} alt="graph" />
          </TextAndImage>
        </div>
      </Container>
    </NinethMainWrapper>
  );
};
