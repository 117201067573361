import { LandingItemTemplate } from "../template/LandingItemTemplate";
import MonitoringImg from "../../assets/Monitoring.svg";
import styled from "styled-components";
import { DescInImageBlack, SubTitleInImageBlack } from "./FifthMain";
import MonitoringInDeviceImg from "../../assets/MonitoringInDevice.svg";
import Timeline from "../../assets/Timeline.svg";

const HorizontalContainer = styled.div`
  display: flex;
  width: 990px;
  height: 788px;
  flex-direction: column;
  gap: 29px;
`;

const RectangleHalfContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 24px;
`;

const LeftRectangleHalfContainer = styled(RectangleHalfContainer)`
  flex-direction: column;
  text-align: center;
  background: linear-gradient(136.44deg, #ceee8f 18.72%, #96e6a1 80.74%);
  padding-top: 49px;

  p:first-child {
    margin-top: 7px;
  }

  p:last-child {
    margin-bottom: 26px;
  }

  img {
    align-self: center;
    width: 851.6px;
    height: 215px;
  }
`;

const RightRectangleHalfContainer = styled(RectangleHalfContainer)`
  height: 372px;
  flex-direction: row;
  /* justify-content: center; */
  padding-top: 56px;
  background: #f5f5f5;

  img {
    width: 552.7px;
    height: 316px;
  }

  & > div > p:nth-child(2) {
    margin-bottom: 15px;
  }

  & > div > p:last-child {
    margin-top: 55px;
    font-weight: 600;
    opacity: 1;
    color: #666666;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  margin: 0 21px 0 57px;
`;

export const SixthMain = () => {
  return (
    <LandingItemTemplate
      shortTitle="모니터링"
      descSentence="모든 데이터를 실시간으로 살펴보고 관리해요."
      shortTitleImage={MonitoringImg}
    >
      <HorizontalContainer>
        <LeftRectangleHalfContainer>
          <SubTitleInImageBlack>
            생산관리의 모든 플로우와 정보를 볼 수 있어요.
          </SubTitleInImageBlack>
          <div>
            <DescInImageBlack>
              각 부서와 실시간으로 모든 정보를 공유하여
            </DescInImageBlack>
            <DescInImageBlack>체계적인 관리가 가능해집니다.</DescInImageBlack>
          </div>
          <img src={MonitoringInDeviceImg} alt="" />
        </LeftRectangleHalfContainer>
        <RightRectangleHalfContainer>
          <TitleBox>
            <SubTitleInImageBlack>생산 공정의 타임라인을</SubTitleInImageBlack>
            <SubTitleInImageBlack>한 곳에서 확인하세요!</SubTitleInImageBlack>
            <div>
              <DescInImageBlack>
                다보플로우와 함께하는 스마트한
              </DescInImageBlack>
              <DescInImageBlack>
                생산 모니터링 시스템을 경험하세요.
              </DescInImageBlack>
            </div>
            <DescInImageBlack>효율성 UP! 생산성 UP!</DescInImageBlack>
          </TitleBox>
          <img src={Timeline} alt="Timeline" />
        </RightRectangleHalfContainer>
      </HorizontalContainer>
    </LandingItemTemplate>
  );
};
