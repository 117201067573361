import styled from "styled-components";
import { SubTitleInImageBlack } from "./FifthMain";

const TenthMainWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 208px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 110px;

  div {
    margin-bottom: 27px;
  }
`;

const Button = styled.div`
  line-height: 29.95px;
  font-weight: 600;
  font-size: 24px;
  background-color: #809cff;
  border-radius: 99px;
  padding: 12px 20px;
  color: #ffffff;
  align-self: flex-start;
  cursor: pointer;
`;
export const TenthMain = () => {
  return (
    <TenthMainWrapper>
      <Container>
        <div>
          <SubTitleInImageBlack>가입만 하면</SubTitleInImageBlack>
          <SubTitleInImageBlack>무료로 체험할 수 있어요!</SubTitleInImageBlack>
        </div>
        <Button>무료로 시작하기 &gt;</Button>
      </Container>
      <Container>
        <div>
          <SubTitleInImageBlack>서비스에 대해</SubTitleInImageBlack>
          <SubTitleInImageBlack>궁금한게 있으신가요?</SubTitleInImageBlack>
        </div>
        <Button>문의하기 &gt;</Button>
      </Container>
    </TenthMainWrapper>
  );
};
