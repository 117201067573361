import styled from "styled-components";
import logo from "../../assets/s_logo.svg";

const LogoWithTitleWrapper = styled.div`
  display: flex;
  height: 38.82px;
  align-items: center;
  /* margin: 3em 4em; */
`;

const SmallLogo = styled.img`
  width: 40px;
  height: 38.82px;
`;

const LogoTitle = styled.p`
  font-size: 32px;
`;

export const LogoWithTitle = () => {
  return (
    <LogoWithTitleWrapper>
      <SmallLogo src={logo} alt="logo" />
      <LogoTitle>Dabo FLOW</LogoTitle>
    </LogoWithTitleWrapper>
  );
};
