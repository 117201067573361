import { LandingItemTemplate } from "../template/LandingItemTemplate";
import InsightImg from "../../assets/insight.svg";
import InsightDashboardImg from "../../assets/insightDashboard.svg";
import styled from "styled-components";
import { DescInImageBlack, SubTitleInImageBlack } from "./FifthMain";

const RectangleDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 990px;
  height: 654px;
  border-radius: 24px;
  background: linear-gradient(136.44deg, #ffe07e 18.72%, #ffb75f 80.74%);
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 87px;

  p:nth-child(2) {
    margin-bottom: 10px;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 146px 42px 0 74px;
`;

export const SeventhMain = () => {
  return (
    <LandingItemTemplate
      shortTitle="인사이트"
      shortTitleImage={InsightImg}
      descSentence="우리 기업의 자산 현황 리포트를 받아보세요."
    >
      <RectangleDiv>
        <TitleBox>
          <TextBox>
            <SubTitleInImageBlack>
              실시간으로 한 눈에 파악하는
            </SubTitleInImageBlack>
            <SubTitleInImageBlack>주요 정보들!</SubTitleInImageBlack>
            <DescInImageBlack>업무 상황을 빠르게 파악하고,</DescInImageBlack>
            <DescInImageBlack>신속하게 대응하세요.</DescInImageBlack>
          </TextBox>
          <TextBox>
            <SubTitleInImageBlack>
              가장 필요하고 궁금했던 정보만
            </SubTitleInImageBlack>
            <SubTitleInImageBlack>쏙쏙 보여드려요.</SubTitleInImageBlack>
            <DescInImageBlack>
              핵심정보들을 놓치지 않게 도와드릴게요.
            </DescInImageBlack>
          </TextBox>
        </TitleBox>
        <img src={InsightDashboardImg} alt="" />
      </RectangleDiv>
    </LandingItemTemplate>
  );
};
