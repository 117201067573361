import "./App.css";
import { Header } from "./UI/header";
import { FifthMain } from "./UI/landingElements/FifthMain";
import { ForthMain } from "./UI/landingElements/ForthMain";
import SecondMain from "./UI/landingElements/SecondMain";
import { SeventhMain } from "./UI/landingElements/SeventhMain";
import { SixthMain } from "./UI/landingElements/SixthMain";
import { ThirdMain } from "./UI/landingElements/ThirdMain";
import { TopMain } from "./UI/landingElements/TopMain";
import { EightthMain } from "./UI/landingElements/EightthMain";
import { NinethMain } from "./UI/landingElements/NinethMain";
import { TenthMain } from "./UI/landingElements/TenthMain";

function App() {
  return (
    <div>
      <Header />
      <TopMain />
      <SecondMain />
      <ThirdMain />
      <ForthMain />
      <FifthMain />
      <SixthMain />
      <SeventhMain />
      <EightthMain />
      <NinethMain />
      <TenthMain />
    </div>
  );
}

export default App;
