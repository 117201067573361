import { LandingItemTemplate } from "../template/LandingItemTemplate";
import homeImage from "../../assets/home.svg";
import styled from "styled-components";
import dashboardInDevices from "../../assets/DeviceImg.svg";

const SqaureDiv = styled.div`
  width: 990px;
  height: 786px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: linear-gradient(144.63deg, #a5c7ff 5.61%, #8587ff 93.56%);
  color: #f4f6fe;

  img {
    height: 416px;
    width: 763px;
  }
  p:first-child {
    margin-top: 75px;
  }

  p:nth-child(2) {
    margin-bottom: 70px;
  }

  p:nth-last-child(2) {
    margin-bottom: 57px;
  }
`;

export const SubTitleInImage = styled.p`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 7px;
`;

export const DescInImage = styled.p`
  font-size: 24px;
`;

export const ForthMain = () => {
  return (
    <LandingItemTemplate
      shortTitle="웹기반"
      shortTitleImage={homeImage}
      descSentence="인터넷만 연결되면 어디서든 접근 가능!"
    >
      <SqaureDiv>
        <SubTitleInImage>입력한 정보들은 바로바로 업데이트!</SubTitleInImage>
        <DescInImage>외부에서도 최신 정보를 빠르게 공유해보세요.</DescInImage>
        <SubTitleInImage>PC, 태블릿, 모바일 완벽 호환</SubTitleInImage>
        <DescInImage>다양한 기기에서 다보플로우를 이용해 보세요!</DescInImage>
        <DescInImage>각 기기에 최적화된 사용성을 느낄 수 있어요.</DescInImage>
        <img src={dashboardInDevices} alt="Dashboard in devices" />
      </SqaureDiv>
    </LandingItemTemplate>
  );
};
