import styled from "styled-components";
import React from "react";

interface LandingItemTemplateWrapperProps extends React.PropsWithChildren {
  shortTitle: string;
  descSentence: string;
  shortTitleImage: string;
}

const LandingItemTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 303px;
`;

const ShortTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 32px;

  img:first-child {
    margin-right: 6px;
  }
`;

const DescSentence = styled.span`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 19px;
  color: #333333;
`;

export const LandingItemTemplate = (props: LandingItemTemplateWrapperProps) => {
  const { shortTitleImage, shortTitle, descSentence, children } = props;
  return (
    <LandingItemTemplateWrapper>
      <ShortTitle>
        <img src={shortTitleImage} alt="home" />
        <p>{shortTitle}</p>
      </ShortTitle>
      <DescSentence>{descSentence}</DescSentence>
      {children && children}
    </LandingItemTemplateWrapper>
  );
};
