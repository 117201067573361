import styled from "styled-components";

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin: 3em 6.56em 3em 0; */
  gap: 46px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5972e4;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
`;

const TextMenu = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

export const Menu = () => {
  return (
    <MenuWrapper>
      <TextMenu>제품소개</TextMenu>
      <TextMenu>요금</TextMenu>
      <TextMenu>고객지원</TextMenu>
      <div>무료 체험하기</div>
    </MenuWrapper>
  );
};
