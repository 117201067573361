import { LandingItemTemplate } from "../template/LandingItemTemplate";
import UIShape from "../../assets/UIShape.svg";
import styled from "styled-components";
import { DescInImage, SubTitleInImage } from "./ForthMain";
import UserFriendlyUIImage from "../../assets/userFriendlyInterface.svg";
import EasyProcessImg from "../../assets/easyProcess.svg";

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;
`;

export const SubTitleInImageBlack = styled(SubTitleInImage)`
  color: #000000;
  opacity: 0.7;
  margin: 0;
`;

export const DescInImageBlack = styled(DescInImage)`
  color: #000000;
  opacity: 0.5;
`;

const HalfContainer = styled.div`
  width: 481px;
  height: 787px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  text-align: left;

  div {
    width: 100%;
  }

  p {
    align-self: flex-start;
    margin-left: 36px;
  }

  div > p:first-child {
    margin-top: 5px;
  }

  p:first-child {
    margin-top: 78px;
  }
`;

const LeftContainer = styled(HalfContainer)`
  background: linear-gradient(136.44deg, #ffd39f 18.72%, #ff9c7f 80.74%);

  p:last-child {
    margin-bottom: 115px;
  }
`;

const RightContainer = styled(HalfContainer)`
  background: linear-gradient(136.44deg, #f2f6fa 18.72%, #f2f3fc 80.74%);

  p:last-child {
    margin-bottom: 82px;
  }
`;

export const FifthMain = () => {
  return (
    <LandingItemTemplate
      shortTitle="직관적인 UI"
      shortTitleImage={UIShape}
      descSentence="아직도 엑셀로 관리하시나요?"
    >
      <VerticalContainer>
        <LeftContainer>
          <SubTitleInImageBlack>
            사용자 친화적인 인터페이스
          </SubTitleInImageBlack>
          <div>
            <DescInImageBlack>쉬운 사용성으로</DescInImageBlack>
            <DescInImageBlack>
              서비스를 쉽고 빠르게 이용하세요.
            </DescInImageBlack>
          </div>
          <img src={UserFriendlyUIImage} alt="User Friendly Interface" />
        </LeftContainer>
        <RightContainer>
          <SubTitleInImageBlack>몇 번의 클릭만으로 끝나는</SubTitleInImageBlack>
          <SubTitleInImageBlack>간편한 업무!</SubTitleInImageBlack>
          <div>
            <DescInImageBlack>불필요한 시간 낭비는 그만!</DescInImageBlack>
            <DescInImageBlack>
              원하는 기능을 쉽고 빠르게 이용할 수 있어요.
            </DescInImageBlack>
          </div>
          <img src={EasyProcessImg} alt="Easy ProcessImage" />
        </RightContainer>
      </VerticalContainer>
    </LandingItemTemplate>
  );
};
