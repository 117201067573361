import styled from "styled-components";
import Curve1 from "../../assets/Curve1.svg";
import Curve2 from "../../assets/Curve2.svg";

const VerticalWrapper = styled.section`
  display: flex;
  /* position: relative; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  text-align: center;

  p {
    margin: 0;
  }

  & > img:first-child {
    position: absolute;
    left: 0;
    /* width: 42.6em; */
    top: 9.75em;
    z-index: -1;
  }

  & > img:last-child {
    position: absolute;
    right: 0;
    width: 34.4em;
    bottom: 58px;
    z-index: -1;
  }
`;

const Title = styled.div`
  width: fit-content;
  margin-bottom: 139.4px;
`;

const TitleText = styled.p`
  font-size: 90px;
  font-weight: bold;
  color: #6c8cff;
  background: linear-gradient(129.35deg, #6c8cff 38.71%, #7966ea 91.9%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0 0 14px 0;
  padding: 0;
`;

const SubTitle = styled.p`
  font-size: 70px;
  font-weight: 700;
  line-height: 79.45px;
  color: #333333;
`;

const SubscribeText = styled.div`
  width: fit-content;
  margin: 0;
  padding: 0;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 22px;
  color: #808080;
`;

const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 284px;
  height: 72px;
  background-color: #5972e4;
  border-radius: 16px;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
`;

export const TopMain = () => {
  return (
    <VerticalWrapper>
      <img src={Curve1} alt="" />
      <Title>
        <TitleText>생산관리의 새로운 시작</TitleText>
        <SubTitle>다보플로우와 함께하세요</SubTitle>
      </Title>
      <SubscribeText>
        <p>생산 데이터 관리를 쉽고 편하게!</p>
        <p>기업의 생산 업무를 통합하여 관리해보세요.</p>
      </SubscribeText>
      <MainButton>일주일 무료체험 &gt;</MainButton>
      <img src={Curve2} alt="" />
    </VerticalWrapper>
  );
};
