import styled from "styled-components";
import glassGraph from "../../assets/glassAndGraph.svg";

const SecondMainWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #809cff;
  width: 100%;
  height: 695px;
  color: #ffffff;
  font-size: 21px;
  font-weight: 500;

  img {
    margin-bottom: 22px;
  }

  div:nth-child(2) {
    font-size: 51px;
    font-weight: 600;
    margin-bottom: 26px;
  }
`;

export default function SecondMain() {
  return (
    <SecondMainWrapper>
      <img src={glassGraph} alt="Glass Graph" />
      <div>
        <p>생산관리 서비스 도입으로 효과적인</p>
        <p>사업전략을 세워보세요.</p>
      </div>
      <p>
        거래처 계약부터 제품 출고까지 모든 과정을 실시간으로 함께 관리해 보세요!
      </p>
      <p>효율적인 생산관리는 기업의 경쟁력으로 이어집니다.</p>
    </SecondMainWrapper>
  );
}
