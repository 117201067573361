import styled from "styled-components";
import { Menu } from "./navigation/Menu";
import { LogoWithTitle } from "../../components/logo/LogoWithTitle";

const HeaderWrapper = styled.div`
  align-items: center;
  /* padding: 3em 6.56em; */
  width: 100%;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    padding: 2em 3em;
  }
`;

export const Header = () => {
  return (
    <HeaderWrapper>
      <div>
        <LogoWithTitle />
        <Menu />
      </div>
    </HeaderWrapper>
  );
};
