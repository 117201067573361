import styled from "styled-components";
import iPhone from "../../assets/iPhone.svg";

const EightMainWrapper = styled.section`
  position: relative;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  background-color: pink;

  img {
    height: 457px;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 32px;
  color: #ffffff;

  p:nth-child(n + 2) {
    font-size: 75px;
    font-weight: 800;
  }

  p:nth-child(2) {
    margin-top: 16px;
  }

  p:last-child {
    margin-bottom: 35px;
  }
`;

const Slogan = styled.p`
  position: absolute;
  bottom: 10px;
  align-self: center;

  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  font-weight: 800;
  font-size: 5rem;
  /* line-height: 105px; */

  text-transform: uppercase;
`;

export const EightthMain = () => {
  return (
    <EightMainWrapper>
      <TextBox>
        <p>Dabo FLOW</p>
        <p>생산관리의 모든 것을 한 곳에서,</p>
        <p>다보플로우에서 만나보세요.</p>
      </TextBox>
      <img src={iPhone} alt="DaboFlow Logo in iPhone" />
      <Slogan>New paradigm of production management</Slogan>
    </EightMainWrapper>
  );
};
