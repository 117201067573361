import styled from "styled-components";
import dashboard from "../../assets/Dashboard.svg";

const ThirdMainWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 13.75em 0 27.5em 0;

  img {
    width: 60%;
  }
`;

const TitleBox = styled.div`
  font-size: 25px;
  /* width: 32%; */
  color: #808080;

  p:first-child {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 41px;
    color: #333333;
  }
`;

export const ThirdMain = () => {
  return (
    <ThirdMainWrapper>
      <img src={dashboard} alt="Dashboard" />
      <TitleBox>
        <p>강력한 기능과 직관적인 UI</p>
        <p>더 쉽고, 더 편리하게 기업의 경쟁력을 높여 보세요.</p>
        <p>체계적이고 효율적인 놀라운 생산관리를 경험해 보세요.</p>
      </TitleBox>
    </ThirdMainWrapper>
  );
};
